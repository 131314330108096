'use strict';

angular.module('informaApp')
    .service('GanttChartHelper', function (d3, ChartElementsHelper, GanttBlockHelper, LazyLoadingService) {
        var result = {};

        result.Chart = function (container, params) {
            var options = {
                minBlockWidth: 50,
                container: container,
                width: 800,
                height: 800,
                yearWidth: 80,
                tickColor: '#BEC2CA',
                timeline: {
                    height: 20,
                    color: '#DDE0E4',
                    activeColor: '#FDD7E5',
                    activeFontColor: '#EF658C',
                    fontSize: 16,
                    fontColor: '#5B5F6A',
                    colorsByStatus: {
                        success: {
                            bg: '#bef3cf',
                            font: '#20ac4c'
                        },
                        failed: {
                            bg: '#FDD7E5',
                            font: '#EF658C'
                        },
                        inProgress: {
                            bg: '#f2f2a6',
                            font: '#9c9c16'
                        }
                    }
                },
                source: {
                    data: [],
                    startDate: new Date(2000, 0, 1),
                    endDate: new Date(2015, 3, 12)
                },
                selectedArea: {
                    colorIndex: '#21AE4B',
                    opacity: 0.5,
                    colorsByStatus: {
                        success: '#d4f7df',
                        failed: '#FDD7E5',
                        inProgress: '#f8f8ce'
                    }
                }
            };

            options.width = options.container.clientWidth === 0 ? options.container.offsetWidth : options.container.clientWidth;

            options = _.merge(options, params);

            var maxHeight = 10 + options.source.data.length * 100;
            maxHeight = maxHeight < options.height ? options.height : maxHeight;

            var startDate = new Date(options.source.startDate.getFullYear(), 0, 1);

            var startDateInDays = getDays(startDate);
            var daysInYear = 365;

            var scrollBarWidth = getScrollBarWidth();

            var chartBodyElement = null;
            var topScroll = null;

            options.container.scrollLeft = 0;

            var years = _.range(options.source.startDate.getFullYear(), options.source.endDate.getFullYear() + 1);

            var yearWidth = options.yearWidth * years.length < (options.width - scrollBarWidth)
                ? (options.width - scrollBarWidth) / years.length
                : options.yearWidth;

            var maxWidth = (years.length * yearWidth) + scrollBarWidth;

            var widthOf1Day = yearWidth / daysInYear;

            d3.select(container).select('.chart-body-container').remove();
            d3.select(container).select('.chart-header').remove();
            d3.select(container).select('.chart-top-scroll-bar').remove();

            var headerMargin = -6;

            createTopScrollBar();

            var svgHeader = d3.select(container)
                .append('svg')
                .attr('class', 'chart-header')
                .attr('width', maxWidth + 'px')
                .attr('height', (options.timeline.height + 1) + 'px');

            var svgBody = createBody();

            var defs = svgBody.append('defs');

            svgBody.attr('width', (maxWidth + scrollBarWidth) + 'px');

            createTimeLine();

            createGrid(svgBody);

            drawBlocksLazy();

            topScroll.onscroll = function (e) {
                options.container.scrollLeft = e.target.scrollLeft;
                scroll(chartBodyElement);
                scroll(topScroll);

                function scroll(target) {
                    target.style['margin-left'] = e.target.scrollLeft + 'px';
                    target.scrollLeft = e.target.scrollLeft;
                }
            };

            function createTopScrollBar() {
                var bodyDivStyle = 'height: ' + scrollBarWidth + 'px;' +
                    'width: ' + options.width + 'px;' +
                    'overflow-x: scroll;' +
                    'overflow-y: hidden';

                var div = d3.select(container)
                    .append('xhtml:div')
                    .attr('class', 'chart-top-scroll-bar')
                    .attr('style', bodyDivStyle);

                topScroll = div.node();

                return div
                    .append('xhtml:div')
                    .attr('style', 'height: 1px; width: ' + maxWidth + 'px;');
            }

            function createBody() {
                var bodyDivStyle = 'height: ' + options.height + 'px;' +
                    'width: ' + options.width + 'px;' +
                    'margin-top: ' + headerMargin + 'px;' +
                    'overflow-x: hidden;' +
                    'overflow-y: scroll';

                var div = d3.select(container)
                    .append('xhtml:div')
                    .attr('class', 'chart-body-container')
                    .attr('style', bodyDivStyle);

                chartBodyElement = div.node();

                return div
                    .append('svg')
                    .attr('class', 'chart-body')
                    .attr('width', options.width + 'px')
                    .attr('height', maxHeight + 'px');
            }

            function createTimeLine() {
                var container = svgHeader.selectAll('.time-block .time-value')
                    .data(years)
                    .enter();

                container.append('rect')
                    .attr('class', 'time-block')
                    .attr('transform', function (d, i) {
                        return getTranslate(getOffsetXByIndex(i), 0);
                    })
                    .attr('width', yearWidth + 'px')
                    .attr('height', options.timeline.height + 'px')
                    .attr('fill', options.timeline.color);

                svgHeader.append('rect')
                    .attr('class', 'time-block-end')
                    .attr('transform', function (d, i) {
                        return getTranslate(getOffsetXByIndex(years.length), 0);
                    })
                    .attr('width', scrollBarWidth + 'px')
                    .attr('height', options.timeline.height + 'px')
                    .attr('fill', options.timeline.color);

                container.append('text')
                    .attr('class', 'time-value')
                    .text(function (d) {
                        return d;
                    })
                    .attr('font-size', options.timeline.fontSize + 'px')
                    .attr('dy', '1.0em')
                    .attr('text-anchor', 'middle')
                    .attr('fill', options.timeline.fontColor)
                    .attr('x', function (d, i) {
                        return getOffsetXByIndex(i) + yearWidth / 2;
                    });

                drawRect(container, 0, 0, maxWidth, 1);
                drawRect(container, 0, options.timeline.height, maxWidth, 1);

                for (var i = 0; i <= years.length; i++) {
                    drawRect(container, getOffsetXByIndex(i) - (i === years.length ? 1 : 0), 0, 1, options.timeline.height);
                }
            }

            function createGrid(container) {
                var grid = container.append('g');

                drawRect(grid, 0, 0, 1, maxHeight);

                for (var i = 1; i < years.length; i++) {
                    drawRect(grid, getOffsetXByIndex(i), 0, 1, maxHeight);
                }

                drawRect(grid, getOffsetXByIndex(years.length) - 1, 0, 1, maxHeight);

                return grid;
            }

            function selectDateRange(container, startDate, total, h) {
                var props = getPropertyByDateRange(startDate, total);

                container.select('.date-range').remove();

                container
                    .append('rect')
                    .attr('class', 'date-range')
                    .attr('transform', getTranslate(props.x, 0))
                    .attr('height', h + 'px')
                    .attr('width', props.width + 'px')
                    .attr('fill', options.selectedArea.color)
                    .attr('opacity', options.selectedArea.opacity);

                var endDate = new Date(startDate);
                endDate.setDate(endDate.getDate() + total);

                var selectedYears = _.range(startDate.getFullYear(), endDate.getFullYear() + 1);

                svgHeader.selectAll('.time-block')
                    .attr('fill', function (d) {
                        return selectedYears.indexOf(d) > -1 ? options.timeline.activeColor : options.timeline.color
                    });

                svgHeader.selectAll('.time-value')
                    .attr('fill', function (d) {
                        return selectedYears.indexOf(d) > -1 ? options.timeline.activeFontColor : options.timeline.fontColor
                    });
            }

            function drawRect(container, x, y, w, h) {
                container
                    .append('rect')
                    .attr('class', 'tick')
                    .attr('transform', getTranslate(x, y))
                    .attr('height', h + 'px')
                    .attr('width', w + 'px')
                    .attr('fill', options.tickColor)
            }

            function getTranslate(x, y) {
                return 'translate(' + x + ',' + y + ')';
            }

            function getOffsetXByIndex(i) {
                return i * yearWidth;
            }


            function getScrollBarWidth() {
                var inner = document.createElement('p');
                inner.style.width = '100%';
                inner.style.height = '200px';

                var outer = document.createElement('div');
                outer.style.position = 'absolute';
                outer.style.top = '0px';
                outer.style.left = '0px';
                outer.style.visibility = 'hidden';
                outer.style.width = '200px';
                outer.style.height = '150px';
                outer.style.overflow = 'hidden';
                outer.appendChild(inner);

                document.body.appendChild(outer);
                var w1 = inner.offsetWidth;
                outer.style.overflow = 'scroll';
                var w2 = inner.offsetWidth;
                if (w1 == w2) w2 = outer.clientWidth;

                document.body.removeChild(outer);

                return (w1 - w2);
            };

            function drawBlocksLazy() {
                const onBlur = () => {
                    getAllBlocksOnScreen().forEach(x => x.style.opacity ='1');

                    clearSelectedDateRange();
                };

                const createNewBlock = (data, index) => {
                    return createBlock(data, index, onBlur);
                };

                const onScroll = () => {
                    onBlur();
                };

                LazyLoadingService.lazyDraw(chartBodyElement, options.source.data, createNewBlock, calculateBlockYPosition, getBlockIndexByYPosition, 80, onScroll);
            }

            function createBlock(item, i, onBlur) {
                const props = getPropertyByDateRange(item.startingDate, item.source.total);
                const y = calculateBlockYPosition(i);

                const onFocus = createOnFocusFunction(item.startingDate, item.source.total, y, item.status.success);

                return addBlock(props.x, y, props.width, item, onFocus, onBlur);
            }

            function calculateBlockYPosition(index) {
                return 10 + index * 100;
            }

            function getBlockIndexByYPosition(y) {
                const index = (y - 10) / 100;

                return Math.round(index);
            }

            function clearSelectedDateRange() {
                svgBody.select('.date-range').remove();

                svgHeader.selectAll('.time-block')
                    .attr('fill', options.timeline.color);

                svgHeader.selectAll('.time-value')
                    .attr('fill', options.timeline.fontColor);
            }

            function createOnFocusFunction(startDate, total, height, success) {
                return function (e) {
                    getAllBlocksOnScreen().forEach(x => x.style.opacity = x !== e.element.node() ? '0.3' : '1');

                    options.timeline.activeColor = options.timeline.colorsByStatus[getPropNameByStatus(success)].bg;
                    options.timeline.activeFontColor = options.timeline.colorsByStatus[getPropNameByStatus(success)].font;
                    options.selectedArea.color = options.selectedArea.colorsByStatus[getPropNameByStatus(success)];
                    selectDateRange(svgBody, startDate, total, height);

                    var deltaRight = (e.maxWidth + e.x) - (options.width + chartBodyElement.scrollLeft);
                    var deltaLeft = chartBodyElement.scrollLeft - e.x;

                    if (deltaRight > 0 || deltaLeft > 0) {
                        var newScrollLeft = deltaRight > 0
                            ? chartBodyElement.scrollLeft + deltaRight + 35
                            : chartBodyElement.scrollLeft - deltaLeft;

                        scrollTo(e.x, newScrollLeft);
                    }
                };
            }

            function getAllBlocksOnScreen() {
                return [...svgBody.node().querySelectorAll('svg.block')]
                    .map(x => x.parentElement);
            }

            function scrollTo(blockX, newScrollLeft) {
                $(topScroll).stop();

                $(topScroll).animate({
                    scrollLeft: newScrollLeft > blockX ? blockX : newScrollLeft
                });
            }

            function getPropNameByStatus(success) {
                return success === true
                    ? 'success'
                    : success == null ? 'inProgress' : 'failed';
            }

            function addBlock(x, y, width, data, onFocus, onBlur) {
                var blockProps = _.merge({
                    container: svgBody,
                    defsElement: defs,
                    width: width < options.minBlockWidth ? options.minBlockWidth : width,
                    x: x,
                    y: y,
                    callbacks: {
                        onFocus: function () {
                            onFocus(e)
                        },
                        onBlur: function () {
                            onBlur(e)
                        }
                    },
                    hideNoDataBar: !data.hasInvalidData
                }, data);

                var e = GanttBlockHelper.createGanttBlock(blockProps);

                return e;
            }

            function getPropertyByDateRange(startDate, duration) {
                var startValue = (getDays(startDate) - startDateInDays);

                var x = startValue * widthOf1Day;
                var width = duration * widthOf1Day;

                return {
                    x: x,
                    width: width
                }
            }

            function getDays(date) {
                return date.getTime() / (1000 * 60 * 60 * 24);
            }
        };

        return result;
    });